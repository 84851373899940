.buttonToggleTheme {
    width: 60px;
    height: 25px;
    border: none;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
}
/* dark */
.themeButton,
.themeButton.light {
    width: 100%;
    height: 100%;
    padding: 2px;
    position: relative;
    overflow: hidden
}
.buttonToggleTheme {
    background-color: #1B1F2F;
}
.toggleTheme {
    background-color: #BFBFBF;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    position: relative;
    z-index: 999;
    transform: translate( 35px, -138px);
    transition: transform .4s ease;
}
.toggleTheme::before {
    content: '';
    position: absolute;
    top: -1px; left: -1px; right: 0; bottom: 0;
    width: 23px;
    height: 23px;
    background-color: #BFBFBF;
    background: linear-gradient(to bottom, #fff 0%,  #BFBFBF 10%, #BFBFBF 90%, #000 100%);
    z-index: -1;
    opacity: .5;
    border-radius: 50%;
}
.circle1,
.circle2,
.circle3 {
    border-radius: 50%;
    position: relative;
}
.circle1 {
    background-color: #4D4F5D;
    width: 34px;
    height: 34px;
    transform: translate(30px, -5px);
    transition: transform .5s ease;
    z-index: 3;
}
.circle2 {
    background-color: #414351;
    width: 46px;
    height: 46px;
    z-index: 2;
    transform: translate(22px, -45px);
    transition: transform .5s ease;
}
.circle3 {
    background-color: #29303A;
    width: 58px;
    height: 58px;
    z-index: 1;
    transform: translate(13px, -97px);
    transition: transform .5s ease;
}
.inRound,
.inRound::after,
.inRound::before {
    background-color: #868686;
    border-radius: 50%;
    position: absolute;
}
.inRound.light {
    opacity: 0;
    transition: opacity .5s ease;
}
.inRound {
    width: 5px;
    height: 5px;
    top: 8px; left: 4px;
    opacity: 1;
    transition: opacity .5s ease;
}
.inRound::after {
    content: '';
    width: 4px;
    height: 4px;
    top: 4px; left: 8px;
}
.inRound::before {
    content: '';
    width: 3px;
    height: 3px;
    bottom: 4px; left: 6px;
}
/* light */
.buttonToggleTheme.light {
    background-color: #3678B6;
}
.toggleTheme.light {
    background-color: #F2CC45;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    position: relative;
    z-index: 20;
    margin-top: 0px;
    margin-left: 0px;
    transform: translate( 0px, -138px);
    transition: transform .4s ease;
}
.circle1.light {
    background-color: #6CA0C7;
    width: 34px;
    height: 34px;
    right: 0;
    z-index: 3;
    transform: translate(-5px, -7px);
    transition: transform .5s ease;
}
.circle2.light {
    background-color: #5E96BC;
    width: 46px;
    height: 46px;
    z-index: 2;
    transform: translate(-10px, -47px);
    transition: transform .5s ease;
}
.circle3.light {
    background-color: #4D8BB8;
    width: 58px;
    height: 58px;
    z-index: 1;
    transform: translate(-15px, -97px);
    transition: transform .5s ease;
}

.sky, .sky_und {
    position: relative;
    transform: translate(15px, -114px);
    transition: transform .5s ease, opacity .5s ease;
    opacity: 0;
}
.sky.light, .sky_und.light {
    position: relative;
    opacity: 1;
}
.sky.light {
    transform: translate(0px, -134px);
    transition: transform .5s ease;
    z-index: 10;
}
.sky_und.light {
    transform: translate(0px, -134px);
    transition: transform .5s ease;
    z-index: 9;
}
.sky.light .sky_round {
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    z-index: 10;
}
.sky.light .sky_round:nth-child(1) {
    width: 16px;
    height: 16px;
    right: -5px; bottom: 4px;
}
.sky.light .sky_round:nth-child(2) {
    width: 16px;
    height: 16px;
    right: 3px; bottom: -2px;
}
.sky.light .sky_round:nth-child(3) {
    width: 13px;
    height: 13px;
    right: 13px; bottom: -5px;
}
.sky.light .sky_round:nth-child(4) {
    width: 8px;
    height: 8px;
    right: 23px; bottom: -3px;
}
.sky.light .sky_round:nth-child(5) {
    width: 13px;
    height: 13px;
    right: 27px; bottom: -6px;
}
.sky.light .sky_round:nth-child(6) {
    width: 17px;
    height: 13px;
    right: 33px; bottom: -7px;
    transform: rotate(-10deg);
}

.sky_und.light .sky_und_round {
    background-color: #AFC9E7;
    border-radius: 50%;
    position: absolute;
    z-index: 9;
}
.sky_und.light .sky_und_round:nth-child(1) {
    width: 16px;
    height: 16px;
    right: -5px; bottom: 7px;
}
.sky_und.light .sky_und_round:nth-child(2) {
    width: 16px;
    height: 16px;
    right: 3px; bottom: 0px;
}
.sky_und.light .sky_und_round:nth-child(3) {
    width: 13px;
    height: 13px;
    right: 13px; bottom: -3px;
}
.sky_und.light .sky_und_round:nth-child(4) {
    width: 8px;
    height: 8px;
    right: 23px; bottom: -1px;
}
.sky_und.light .sky_und_round:nth-child(5) {
    width: 13px;
    height: 13px;
    right: 27px; bottom: -4px;
}
.sky_und.light .sky_und_round:nth-child(6) {
    width: 17px;
    height: 13px;
    right: 35px; bottom: -5px;
    transform: rotate(-10deg);
}
.inRound.light {
    display: none;
}

.cosmosstar {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 30;
    transform: translate(0, -160px);
}

.starButton {
    position: absolute;
}
.starButton1 {
    width: 3px;
    height: 3px;
    transform: translate(2px, -5px);
    transition: transform .5s ease;
}
.starButton2 {
    width: 2px;
    height: 2px;
    transform: translate(4px, 5px);
    transition: transform .5s ease;
}
.starButton3 {
    width: 1px;
    height: 1px;
    transform: translate(-7px, 0px);
    transition: transform .5s ease;
}
.starButton4 {
    width: 2px;
    height: 2px;
    transform: translate(-20px, -5px);
    transition: transform .5s ease;
}
.starButton5 {
    width: 3px;
    height: 3px;
    transform: translate(-20px, 5px);
    transition: transform .5s ease;
}
.starButton6 {
    width: 3px;
    height: 3px;
    transform: translate(-13px, 8px);
    transition: transform .5s ease;
}


.starButton1.light {
    width: 2px;
    height: 2px;
    transform: translate(2px, -15px);
    transition: transform .5s ease, width .5s ease, height .5s ease;
}
.starButton2.light {
    width: 1px;
    height: 1px;
    transform: translate(4px, 15px);
    transition: transform .5s ease, width .5s ease, height .5s ease;
}
.starButton3.light {
    width: 0px;
    height: 0px;
    transform: translate(-10px, -20px);
    transition: transform .5s ease, width .5s ease, height .5s ease;
}
.starButton4.light {
    width: 1px;
    height: 1px;
    transform: translate(-40px, -5px);
    transition: transform .5s ease, width .5s ease, height .5s ease;
}
.starButton5.light {
    width: 1px;
    height: 1px;
    transform: translate(-25px, 25px);
    transition: transform .5s ease, width .5s ease, height .5s ease;
}
.starButton6.light {
    width: 1px;
    height: 1px;
    transform: translate(-13px, 18px);
    transition: transform .5s ease, width .5s ease, height .5s ease;
}

@media ( max-width: 769px ) {
    .buttonToggleTheme {
        position: relative;
        z-index: 110;
    }
}