.reviewCard_container1,
.reviewCard_container2,
.reviewCard_container3,
.reviewCard_container4,
.reviewCard_containerRu1,
.reviewCard_containerRu2,
.reviewCard_containerRu3,
.reviewCard_containerRu4 {
    background-color: #2B2A61;
    border-radius: 50px;
    position: relative;
    width: 87%;
    min-height: 160px;
    padding: 30px 40px 30px 55px;
    margin: 0 40px;
}
.reviewCard_image {
    position: absolute;
    top: 25%;
    left: -35px;
    width: 75px;
    height: 75px;
    mask: url('../../../images/maskUsers.png');
    mask-size: 75px;
}
.reviewCardNameAndButton {
    display: flex;
    justify-content: space-between;
}
.reviewCard_text {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.reviewCard_name {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}
.reviewCard_message,
.reviewCard_message_en,
.reviewCard_message_ru {
    font-size: 14px;
    font-weight: 300;
    color: #fff9;
}
.reviewCard_message.light,
.reviewCard_message_en.light,
.reviewCard_message_ru.light {
    color: #fff9;
}
.reviewCard_en.displayNone,
.reviewCard_ru.displayNone {
    display: none;
}

.reviewCard-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.reviewCardButton button {
    width: 100px;
    background: linear-gradient(to right, #B931F9 0%, #63B9F7 100%);
    border: none;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}

@media ( max-width: 1340px ) {
    .reviewCard_container,
    .reviewCard_containerRu {
        max-height: 170px;
    }
}
@media ( max-width: 980px ) {
    .reviewCard_container,
    .reviewCard_containerRu {
        padding: 30px 20px 30px 55px;
    }
    .reviewCard_image {
        top: 30%;
        left: -27px;
        width: 65px;
        height: 65px;
        mask-size: 65px;
    }
}
@media ( max-width: 650px ) {
    .reviewCard_container,
    .reviewCard_containerRu {
        width: 85%;
        min-height: 190px;
        padding: 30px 20px 30px 45px;
    }
    .reviewCardButton button {
        width: 100%;
        padding: 5px 15px;
        font-size: 14px;
    }
}
@media ( max-width: 520px ) {
    .reviewCard_container,
    .reviewCard_containerRu {
        width: 90%;
        min-height: 150px;
        max-height: 300px;
    }
    .reviewCard_image {
        top: 28%;
        left: -25px;
        width: 60px;
        height: 60px;
        mask-size: 60px;
    }
    .reviewCardButton button {
        width: 100px;
        padding: 5px 10px;
        font-size: 16px;
    }
}
@media ( max-width: 375px ) {
    .reviewCard_container,
    .reviewCard_containerRu {
        width: 83%;
    }
    .reviewCard_image {
        top: 35%;
        left: -20px;
        width: 50px;
        height: 50px;
        mask-size: 50px;
    }
}
@media ( max-width: 340px ) {
    .reviewCardButton button {
        width: 100%;
        padding: 5px 15px;
        font-size: 14px;
    }
}