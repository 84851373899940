.about_container {
    position: relative;
    margin: 80px 100px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.about_container h1,
.about_container p {
    color: #fff;
    width: 100%;
    text-align: center;
}
.about_container.light h1,
.about_container.light p {
    color: #000;
}
.about_container h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}
.about_container p {
    font-size: 14px;
    max-width: 1000px;
}
.gradientTextTitle {
    background: linear-gradient(45deg, #B931F9 0%, #63B9F7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.about_content {
    display: flex;
    justify-content: space-around;
    gap: 100px;
}
.about_card {
    background-color: #2B2A61;
    border-radius: 140px;
    padding: 60px 40px 40px;
    position: relative;
}
.about_card.light {
    background-color: rgba(43, 42, 97, .7);
}
.hand {
    position: absolute;
    width: 40px;
    height: auto;
    top: 20px;
    left: 20px;
}
.hello > span {
    color: #63B9F7;
    font-size: 24px;
    font-weight: 600;
    width: 100%;
}
.my_name {
    color: #fff;
    font-weight: 400;
    font-size: 24px !important;
    width: 100%;
}
.my_name.light,
.my_name_ru.light {
    color: #fff !important;
}
.about_image {
    background-color: rgba(99, 185, 247, .3);
    border-radius: 110px;
    width: 220px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    margin-top: 30px;
}
.about_image img {
    object-fit: cover;
    width: 230px;
    height: auto;
    pointer-events: none;
}

.about_table {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 60px;
}
.about_table_item:nth-child(1),
.about_table_item:nth-child(3) {
    padding: 20px 40px 20px 20px;
}
.about_table_item:nth-child(2),
.about_table_item:nth-child(4) {
    padding: 20px 20px 20px 40px;
}
.about_table_item h2,
.about_table_item p {
    color: #fff;
}
.about_table_title1.light,
.about_table_title2.light,
.about_table_title3.light,
.about_table_title4.light,
.about_table_title1_ru.light,
.about_table_title2_ru.light,
.about_table_title3_ru.light,
.about_table_title4_ru.light {
    color: #000;
}
.about_table_item h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
}
.about_table_item p {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    position: relative;
}
.about_table_item p::after {
    content: '';
    background-color: #63B9F7;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    left: -25px; top: 2px;
}
.about_table_item:nth-child(1) {
    border-right: 2px solid rgba(99, 185, 247, .5);
    border-bottom: 2px solid rgba(99, 185, 247, .5);
}
.about_table_item:nth-child(2) {
    border-bottom: 2px solid rgba(99, 185, 247, .5);
}
.about_table_item:nth-child(3) {
    border-right: 2px solid rgba(99, 185, 247, .5);
}


@media ( max-width: 1200px ) {
    .about_content {
        gap: 40px;
    }
}
@media ( max-width: 1030px ) {
    .about_container {
        margin: 80px 50px 80px;
    }
    .about_content {
        flex-direction: column;
    }
    .about_content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
    }
    .about_card {
        max-width: 320px;
    }
}
@media ( max-width: 769px ) {
    .about_table {
        grid-template-columns: repeat(1, 1fr);
        margin-top: 60px;
    }
    .about_table_item:nth-child(1) {
        border-right: none;
        border-bottom: 2px solid rgba(99, 185, 247, .5);
    }
    .about_table_item:nth-child(2),
    .about_table_item:nth-child(3) {
        border-bottom: 2px solid rgba(99, 185, 247, .5);
    }
    .about_table_item:nth-child(3) {
        border-right: none;
    }
    .about_table_item:nth-child(1),
    .about_table_item:nth-child(2),
    .about_table_item:nth-child(3),
    .about_table_item:nth-child(4) {
        padding: 20px;
        width: 100%;
    }
}
@media ( max-width: 550px ) {
    .about_container {
        margin: 80px 1rem 80px;
    }
}