.mobile_footer_container {
    display: flex;
    padding: 10px 20px;
    align-items: center;
    background-color: #2B2A61;
    flex-direction: row;
}
.mobile_footer_column {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.mobile_footer_column:nth-child(1) div:nth-child(1) {
    color: #fff;
    font-size: 14px;
}
.mobile_policy_en,
.mobile_policy_ru {
    color: #fff;
    font-size: 12px;
    font-weight: 200;
    margin-top: 5px;
}

.mobile_up_en,
.mobile_up_ru {
    display: flex;
    justify-content: right;
    align-items: end;
    max-width: 80px;
}
.mobile_up_en a,
.mobile_up_ru a {
    max-width: 80px;
    color: #191840;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 300;
    background-color: #63B9F7;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
}

@media ( min-width: 541px ) {
    .mobile_footer_container {
        display: none;
    }
}
@media ( max-width: 540px ) {
    .mobile_footer_container {
        display: flex;
    }
}