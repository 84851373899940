.stagesDevCard-container {
    position: relative;
}
.stagesDevCard-front {
    background-color: #2B2A61;
    border-radius: 10px;
    width: 225px;
    height: 240px;
    overflow: hidden;
    position: relative;
    padding: 20px;
    transform: rotateY(0);
    transition: transform .7s ease;
    z-index: 2;
}
.stagesDevCard-front1.light,
.stagesDevCard-front2.light,
.stagesDevCard-front3.light,
.stagesDevCard-front4.light {
    background-color: #555581;
}
.stagesDevCard-front_number {
    font-size: 170px;
    font-weight: 600;
    color: #fff2;
    position: absolute;
    top: -70px; left: -10px;
    z-index: -1;
}
.stagesDevCard-front_content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.stagesDevCard-front_content-image {
    width: 100%;
    display: flex;
    justify-content: center;
}
.stagesDevCard-front_content-image > img {
    max-height: 160px;
    width: auto;
    object-fit: cover;
}
.stagesDevCard-front_content-title {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
}

.stagesDevCard-back {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #B931F9 0%, #63B9F7 100%);
    border-radius: 10px;
    width: 225px;
    height: 240px;
    overflow: hidden;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    transform: rotateY(180deg);
    transition: transform .7s ease;
    z-index: 1;
}
.stagesDevCard-back h3 {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
}
.stagesDevCard-back p {
    color: #fff7;
    font-size: 14px;
}


.stagesDevCard-container:hover .stagesDevCard-front {
    animation: frontCard .7s linear forwards;
}
.stagesDevCard-container:hover .stagesDevCard-back {
    animation: backCard .7s linear forwards;
}
.stagesDevCard-container:not(:hover) .stagesDevCard-front {
    animation: frontCardBack .7s linear forwards;
}
.stagesDevCard-container:not(:hover) .stagesDevCard-back {
    animation: backCardBack .7s linear forwards;
}

@keyframes frontCard {
    from {
        transform: rotateY(0);
        transition: transform .7s ease;
    }
    to {
        transform: rotateY(-180deg);
        z-index: 1;
        transition: transform .7s ease;
    }
}
@keyframes backCard {
    from {
        transform: rotateY(-180deg);
        transition: transform .7s ease;
    }
    to {
        transform: rotateY(0);
        z-index: 2;
        transition: transform .7s ease;
    }
}
@keyframes frontCardBack {
    from {
        transform: rotateY(-180deg);
        z-index: 1;
        transition: transform .7s ease;
    }
    to {
        transform: rotateY(0);
        transition: transform .7s ease;
    }
}
@keyframes backCardBack {
    from {
        transform: rotateY(0);
        z-index: 2;
        transition: transform .7s ease;
    }
    to {
        transform: rotateY(-180deg);
        transition: transform .7s ease;
    }
}

@media ( max-width: 1085px ) {
    .stagesDevCard-container {
        width: 100%;
    }
    .stagesDevCard-front,
    .stagesDevCard-back {
        width: 100%;
        height: 240px;
    }
}
@media ( max-width: 545px ) {
    .stagesDevCard-container {
        width: 100%;
    }
}