.reviews_container {
    position: relative;
    margin: 50px 0 70px;
}
.reviews_title,
.reviews_title_ru {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    width: 100%;
    text-align: center;
    margin-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
}
.reviews_title.light {
    color: #000;
}
.slick-arrow.slick-prev,
.slick-arrow.slick-next {
    display: none;
}

@media ( max-width: 420px ) {
    .reviews_title {
        font-size: 18px;
        width: 100%;
    }
}