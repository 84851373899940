.caseProjectCard_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 150px;
    gap: 50px;
}
.caseProjectCard-card {
    width: 100%;
    overflow: hidden;
    background-color: #2B2A61;
    border-radius: 15px;
    position: relative;
}
.caseProjectCard-card img {
    width: 100%;
    object-fit: cover;
}
.caseProjectCard_content {
    margin: 10px 10px 20px;
}
.caseProjectCard_content-title {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    color: #fff;
}
.caseProjectCard_content-stacks {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
}
.caseProjectCard-stack {
    background-color: rgb(99, 185, 247, .2);
    border-radius: 10px;
    padding: 2px 10px;
}
.caseProjectCard-stack span {
    color: #fff;
    font-size: 12px;
    font-weight: 100;
}

.caseProjectCard-card-Link {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: rgba(0, 0, 0, .6);
    backdrop-filter: blur(2px);
    z-index: 19;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 12px 4px rgba(101, 183, 248, .5);
    opacity: 0;
    transition: opacity .5s linear;
    width: 100%;
    height: 100%;
}
.caseProjectCard-Link-icon {
    background-color: #63B9F7;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    transition: transform .5s linear;
}
.caseProjectCard-Link-icon:hover {
    transform: scale(1.04);
}
.caseProjectCard-Link-icon-btn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
}

.caseProjectCard-Link-icon-btn img {
    max-width: 30px;
    max-height: 30px;
}
.caseProjectCard-card:hover {
    border: 1px solid #65B7F8;
    z-index: 20;
    & .caseProjectCard-card-Link {
        opacity: 1;
    }
}


@media ( max-width: 1200px ) {
    .caseProjectCard_container {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media ( max-width: 900px ) {
    .caseProjectCard_container {
        margin: 0 50px;
        gap: 30px;
    }
}
@media ( max-width: 769px ) {
    .caseProjectCard_container {
        grid-template-columns: repeat(2, 1fr);
        margin: 0 20px;
        gap: 20px;
    }
}

