form {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.form_title,
.form_item_title1,
.form_item_title2,
.form_item_title3,
.form_item_title4 {
    color: #4C4B80;
    font-size: 16px;
    font-weight: 400;
}
.form_item {
    display: flex;
    margin-left: 20px;
}
.form_item_title1,
.form_item_title2,
.form_item_title3,
.form_item_title4 {
    width: 100px;
}
.marksOne1,
.marksOne2,
.marksTwo1,
.marksTwo2,
.marksThree1,
.marksThree2,
.marksFour1,
.marksFour2 {
    color: #4C4B80;
    font-size: 16px;
    margin-right: 5px;
}

.form_item_input1 input,
.form_item_input2 input,
.form_item_input4 input {
    background-color: transparent;
    border: none;
    width: 170px;
    caret-color: #63B9F7;
    color: #63B9F7;
    font-size: 16px;
    font-weight: 500;
    margin-right: 5px;
}
.form_item_input1 input:focus, 
.form_item_input2 input:focus, 
.form_item_input4 input:focus {
    outline: none;
}
.form_item_input1 input:focus::placeholder, 
.form_item_input2 input:focus::placeholder,
.form_item_input4 input:focus::placeholder {
    opacity: 0;
    transition: opacity .3s linear;
}

.form_item_input1 input::placeholder,
.form_item_input2 input::placeholder,
.form_item_input4 input::placeholder {
    background: linear-gradient(45deg, #B931F9 0%, #63B9F7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    font-weight: 600;
}
.colorForm {
    color: #63B9F7;
}
.form_button_btn {
    border: none;
    width: 100%;
    text-align: left;
    background-color: transparent;
    font-size: 16px;
    color: #fff;
    font-family: "Poppins", sans-serif;
    margin-top: 35px;
    position: relative;
}
.form_button_btn_bg {
    position: absolute;
    top: 0; left: 0;
    height: 100%;
    background-color: #0002;
    width: 0%;
    z-index: -1;
    transition: width .5s linear;
}
@media ( hover :hover ) {
    .form_button_btn:hover .form_button_btn_bg {
        width: 100%;
    }
}
@media ( hover :active ) {
    .form_button_btn:active .form_button_btn_bg {
        width: 100%;
    }
}
.form_button_btn.light {
    color: #000;
}
.form_button_btn-bracket {
    color:#B931F9;
}
.form_button_btn-form {
    color: #63B9F7;
}


.form_item_input3 select {
    background-color: transparent;
    border: none;
    width: 170px;
    caret-color: #63B9F7;
    color: #B931F9;
    font-size: 16px;
    font-weight: 600;
    margin-right: 9px;  
    background: linear-gradient(45deg, #B931F9 0%, #63B9F7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: -4px;
}
.form_item_input3 select option {
    background-color: #191840;
    font-size: 16px;
    font-weight: 300;
    color: #B931F9;
}
.form_item_input3 select:focus {
    outline: none;
}

.messageAboutSendBlock {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 300;
}
.messageAboutSendBlock.notMessage {
    display: none;
}
.messageAboutSend {
    background-color: #2B2A61;
    width: 440px;
    min-height: 285px;
    max-height: 310px;
    border-radius: 20px;
    box-shadow: 0 0 50px 30px #0005;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.messageAboutSend_image {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}
.messageAboutSend_image img {
    width: 60px;
    height: 60px;
}
.messageAboutSend_resultMessage {
    font-size: 18px;
    color: #fff;
    margin-bottom: 7px;
}
.messageAboutSend_textMessage {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.messageAboutSend_buttonMessage {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 23px;
}
.messageAboutSend_buttonMessage button {
    min-width: 190px;
    max-width: 210px;
    padding: 10px 50px;
    background-color: transparent;
    border: 1px solid #fff3;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    box-shadow: none;
    transition: box-shadow .5s ease;
    background: linear-gradient(to right, rgba(185, 49, 249, .75) 0%, rgba(99, 185, 247, .75) 100%);
}
.messageAboutSend_buttonMessage button:hover {
    box-shadow: 0 0 8px 7px rgba(101, 183, 248, .3),
                        inset 0 0 8px 7px rgba(101, 183, 248, .3);
}
.messageAboutSend_buttonMessage button span {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
.messageAboutSend_buttonMessageGrad {
    width: 250px;
    height: 250px;
    background: linear-gradient(to right, #B931F9 0%, #63B9F7 100%);
    position: absolute;
    z-index: -1;
    transition: opacity .5s ease;
    top: -100px; left: -40px;
}
.messageAboutSend_buttonMessageBG {
    position: absolute;
    z-index: -1;
    top: 0; right: 0; bottom: 0; left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .25);
}

.messageAboutSend_textMessageError {
    font-size: 12px;
    color: #A34D63;
    margin-top: 5px;
}

@media (max-width: 678px ) {
    .messageAboutSend {
        width: 400px;
        min-height: 270px;
        max-height: auto;
    }
}
@media (max-width: 540px ) {
    .messageAboutSend {
        width: 90%;
        min-height: 270px;
        max-height: auto;
    }
}