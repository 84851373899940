.stagesOfImplementation_container {
    position: relative;
    margin-top: 100px;
}
.stagesOfImplementation_title {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
}
.stagesOfImplementation_title.light {
    color: #000;
}
.stagesOfImplementation_content {
    margin: 70px 150px 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.stagesOfImplementation_content_item {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
}
.stagesOfImplementation_item_image img {
    mask: url('../../images/mask1.png');
    mask-repeat: no-repeat;
    mask-position: center;
}
.stagesOfImplementation_item_image.right img {
    mask: url('../../images/mask2.png');
    mask-repeat: no-repeat;
    mask-position: center;
}
.stagesOfImplementation_item_info {
    display: flex;
    height: 150px;
}
.stagesOfImplementation_item_info_lineOne {
    width: 125px;
    height: 95px;
    border-right: 1px solid #fff;
    border-left: none;
    display: flex;
    align-items: end;
    gap: 14px;
}
.stagesOfImplementation_item_info_lineOne.one.light,
.stagesOfImplementation_item_info_lineOne.three.light {
border-right: 1px solid #000;
}
.stagesOfImplementation_item_info_lineOne.two.light,
.stagesOfImplementation_item_info_lineOne.four.light {
    border-left: 1px solid #000;
}
.stagesImpLineOne1,
.stagesImpLineOne5,
.stagesImpLineTwo1,
.stagesImpLineTwo5,
.stagesImpLineThree1,
.stagesImpLineThree5,
.stagesImpLineFour1,
.stagesImpLineFour5 {
    width: 9px;
    height: 1px;
    background-color: #fff;
}
.stagesImpLineOne2,
.stagesImpLineOne3,
.stagesImpLineOne4 
.stagesImpLineTwo2,
.stagesImpLineTwo3,
.stagesImpLineTwo4 
.stagesImpLineThree2,
.stagesImpLineThree3,
.stagesImpLineThree4 
.stagesImpLineFour2,
.stagesImpLineFour3,
.stagesImpLineFour4 {
    width: 16px;
    height: 1px;
    background-color: #fff;
}
.stagesImpLineOne1.light,
.stagesImpLineOne5.light,
.stagesImpLineTwo1.light,
.stagesImpLineTwo5.light,
.stagesImpLineThree1.light,
.stagesImpLineThree5.light,
.stagesImpLineFour1.light,
.stagesImpLineFour5.light,
.stagesImpLineOne2.light,
.stagesImpLineOne3.light,
.stagesImpLineOne4.light,
.stagesImpLineTwo2.light,
.stagesImpLineTwo3.light,
.stagesImpLineTwo4.light,
.stagesImpLineThree2.light,
.stagesImpLineThree3.light,
.stagesImpLineThree4.light,
.stagesImpLineFour2.light,
.stagesImpLineFour3.light,
.stagesImpLineFour4.light {
    background-color: #000;
}
.stagesOfImplementation_item_info_lineTwo {
    border-top: 1px solid #fff;
    width: 200px;
}
.stagesOfImplementation_item_info_lineTwo.one.light,
.stagesOfImplementation_item_info_lineTwo.two.light,
.stagesOfImplementation_item_info_lineTwo.three.light,
.stagesOfImplementation_item_info_lineTwo.four.light {
    border-top: 1px solid #000;
}
.stagesOfImplementation_item_info_lineTwo.one.light div,
.stagesOfImplementation_item_info_lineTwo.two.light div,
.stagesOfImplementation_item_info_lineTwo.three.light div,
.stagesOfImplementation_item_info_lineTwo.four.light div {
    background-color: #000;
}
.stagesOfImplementation_item_info_content {
    display: flex;
    flex-direction: column;
}
.stagesOfImplementation_item_info_content.right {
    justify-content: right;
    align-items: end;
}
.stagesOfImplementation_item_info_content_title {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0 15px 20px;
}
.stagesOfImplementation_item_info_content_title.light,
.stagesOfImplementation_title_ru.light {
    color: #000;
}
.stagesOfImplementation_item_info_content_title.right {
    text-align: right;
    margin: 10px 20px 15px 0px;
}
.stagesOfImplementation_item_info_content_text {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    margin-left: 20px;
    max-width: 410px;
}
.stagesOfImplementation_item_info_content_text.light 
.stagesOfImplementation_item_info_content_title1_en.light,
.stagesOfImplementation_item_info_content_title2_en.light,
.stagesOfImplementation_item_info_content_title3_en.light,
.stagesOfImplementation_item_info_content_title4_en.light,
.stagesOfImplementation_item_info_content_text1_en.light,
.stagesOfImplementation_item_info_content_text2_en.light,
.stagesOfImplementation_item_info_content_text3_en.light,
.stagesOfImplementation_item_info_content_text4_en.light,
.stagesOfImplementation_item_info_content_title1_ru.light,
.stagesOfImplementation_item_info_content_title2_ru.light,
.stagesOfImplementation_item_info_content_title3_ru.light,
.stagesOfImplementation_item_info_content_title4_ru.light,
.stagesOfImplementation_item_info_content_text1_ru.light,
.stagesOfImplementation_item_info_content_text2_ru.light,
.stagesOfImplementation_item_info_content_text3_ru.light,
.stagesOfImplementation_item_info_content_text4_ru.light {
    color: #000;
}
.stagesOfImplementation_item_info_content_text.right {
    text-align: right;
    margin-right: 20px;
}
.stagesOfImplementation_item_info_lineOne.right {
    border-left: 1px solid #fff;
    border-right: none;
}
.stagesOfImplementation_item_info_lineOne.left div:nth-child(5) {
    margin-left: 2px;
}
.stagesOfImplementation_attention {
    max-width: 950px;
    display: flex;
    margin: 0 auto;
    margin-bottom: 100px;
}
.attention_title {
    display: flex;
    flex-direction: column;
    line-height: 80%;
}
.attention_title span {
    color: #63B9F7;
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
}
.attention_text {
    font-size: 16px;
    font-weight: 300;
    color: #fff;
    margin: 0 30px;
}
.attention_text.light,
.attention_text_ru.light {
    color: #000;
}
.attention_text_en,
.attention_text_ru {
    text-align: justify;
}

@media ( max-width: 1100px ) {
    .stagesOfImplementation_content {
        margin: 70px 100px 140px;
    }
    .stagesOfImplementation_item_image img {
        mask-size: 250px;
        max-width: 250px;
    }
    .stagesOfImplementation_item_image.right img {
        mask-size: 250px;
        max-width: 250px;
    }
    .stagesOfImplementation_item_info_content_text {
        max-width: 100%;
    }
    .stagesOfImplementation_attention {
        max-width: 100%;
        margin: 20px;
    }
}
@media ( max-width: 850px ) {
    .stagesOfImplementation_content {
        margin: 70px 40px 140px;
    }
    .stagesOfImplementation_content_item {
        flex-direction: column;
    }
    .stagesOfImplementation_content_item:nth-child(2),
    .stagesOfImplementation_content_item:nth-child(4) {
        flex-direction: column-reverse;
    }
    .stagesOfImplementation_item_info_lineOne div {
        display: none;
    }
}
@media ( max-width: 769px ) {
    .stagesOfImplementation_content {
        margin: 70px 20px 140px;
    }
    .stagesOfImplementation_title {
        font-size: 22px;
    }
    .stagesOfImplementation_item_info_lineOne.left,
    .stagesOfImplementation_item_info_lineOne.right {
        width: 1px;
    }
    .stagesOfImplementation_item_info_content_text {
        font-size: 14px;
    }
    .attention_text,
    .attention_text_ru {
        font-size: 14px;
    }
}
@media ( max-width: 450px ) {
    .stagesOfImplementation_content {
        gap: 20px;
    }
    .stagesOfImplementation_item_image img {
        mask-size: 200px;
        max-width: 200px;
    }
    .stagesOfImplementation_item_image.right img {
        mask-size: 200px;
        max-width: 200px;
    }
    .attention_text,
    .attention_text_ru {
        margin: 0 15px;
    }
    .stagesOfImplementation_title {
        font-size: 18px;
        text-align: center;
    }
}