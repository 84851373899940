.reviewActive_container.closeActiveReview {
    display: none;
}
.reviewActive_container {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
}
.reviewActive_container::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba( 0, 0, 0, .5 );
    backdrop-filter: blur( 10px );
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    z-index: -1;
}
.reviewActive_contentBlock {
    background-color: #191840;
    width: 500px;
    height: auto;
    border-radius: 20px;
    padding: 20px;
    padding-bottom: 40px;
}
.reviewActive_title {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
}
.reviewActive_title div:nth-child(3) {
    display: flex;
    justify-content: right;
}
.reviewActive_content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.reviewActive_ImageName {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 20px;
}
.reviewActive_image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.btnToggleCloseActiveReview {
    background-color: transparent;
    border: none;
}
.btnToggleCloseActiveReview span {
    color: #fff;
    font-size: 30px;
}

.reviewActive_name {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
}
.reviewActive_message {
    color: #fff;
    font-size: 16px;
    font-weight: 200;
}


@media ( max-width: 530px ) {
    .reviewActive_contentBlock {
        width: 90%;
    }
}