.caseActive_container {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba( 0, 0, 0, .7);
    backdrop-filter: blur(5px);
}
.caseActive_contentBlock {
    position: relative;
    background-color: #2B2A61;
    padding: 10px 10px 40px;
    transition: box-shadow .7s ease;
    max-width: 1030px;
    max-height: 600px;
    min-height: 250px;
    border-radius: 20px;
}
.caseActive_title {
    display: flex;
    justify-content: right;
    width: 100%;
}
.caseActive_buttonClose {
    background-color: transparent;
    border: none;
    &:hover span {
        text-shadow: 0 0px 20px #63B9F7;
    }
    & span {
        color: #fff;
        max-height: 40px;
        font-size: 30px;
    }
}
.caseActive_content {
    padding: 10px 40px 40px;
    /* scroll-behavior: smooth;*/
}

.caseActive_imageAndTitle {
    display: flex;
    gap: 50px;
}
.caseActive_imageAndTitle_image img {
    max-width: 345px;
    border-radius: 15px;
    pointer-events: none;
}
.caseActive_imageAndTitle_title {
    display: flex;
    flex-direction: column;
}
.caseActive_imageAndTitle_title_item {
    display: flex;
    gap: 50px;
    align-items: center;
}
.caseActive_imageAndTitle_title_item:nth-child(1) {
    margin-bottom: 5px;
}
.caseActive_imageAndTitle_title_item_main {
    max-width: 50px;
    color: #fff2;
    font-size: 20px;
    font-weight: 500;
}
.caseActive_imageAndTitle_title_item_name {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}
.caseActive_imageAndTitle_title_item_stacks {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}
.caseActive_imageAndTitle_title_item_stack {
    background-color: rgb(99, 185, 247, .2);
    border-radius: 10px;
    padding: 2px 10px;
}
.caseActive_imageAndTitle_title_item_stack span {
    color: #fff;
    font-size: 12px;
    font-weight: 100;
}
.caseActive_imageAndTitle_title_button {
    width: 100%;
    display: flex;
    margin-top: 25px;
}
.caseActive_imageAndTitle_title_button_link {
    border: 1px solid #fff5;
    max-width: 250px;
    position: relative;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    font-weight: 100;
    padding: 10px 40px;
    border-radius: 50px;
    overflow: hidden;
    transition: all .5s ease;
    &:hover {
        padding: 10px 60px 10px 40px;
    }
    &:hover img {
        transform: translateX(0);
    }
}

.caseActive_imageAndTitle_title_button_link img {
    position: absolute;
    right: 30px;
    max-width: 25px;
    opacity: .5;
    transform: translateX(100px);
    transition: transform .5s ease;
    pointer-events: none;
}

.caseActive_abouts {
    margin: 20px 0 0 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.caseActive_about {
    color: #fff;
    font-size: 14px;
    font-weight: 200;
    position: relative;
}
.caseActive_about::before {
    content: '';
    position: absolute;
    left: -22px; top: 4px;
    width: 12px;
    height: 12px;
    background-color: #63B9F7;
    border-radius: 50%;
}


@media ( max-width: 1070px ) {
    .caseActive_contentBlock {
        padding: 5px 0px 20px;
        max-width: 100%;
        width: 100%;
        margin-left: 20px;
        margin-right: 20px;
    }
    .caseActive_imageAndTitle {
        gap: 30px;
    }
}
@media ( max-width: 768px ) {
    .caseActive_imageAndTitle_image {
        max-width: 100%;
    }
    .caseActive_imageAndTitle_image img {
        max-width: 250px;
    }
    .caseActive_content {
        padding: 10px 20px 20px;
    }
    .caseActive_contentBlock {
        overflow-x: hidden;
        padding: 5px 0px 20px;
        max-height: 90%;
    }
}
@media ( max-width: 540px ) {
    .blockAndTitles {
        display: flex;
    }
    .caseActive_contentBlock {
        border-radius: 20px 0 0 20px;
    }
    .blockAndTitles {
        gap: 30px;
    }
    .caseActive_imageAndTitle {
        flex-direction: column;
    }
    .caseActive_imageAndTitle_image img {
        max-width: 100%;
    }
    .caseActive_imageAndTitle_title {
        flex-direction: column;
    }
    .caseActive_imageAndTitle_title_item {
        gap: 10px;
        justify-content: left;
        align-items: start;
    }
    .caseActive_imageAndTitle_title_item {
        flex-direction: column;
    }
    .caseActive_imageAndTitle_title_item_main {
        max-width: 50px;
        font-size: 18px;
    }
    .caseActive_imageAndTitle_title_item_name {
        font-size: 14px;
    }
}
@media ( max-width: 440px ) {
    .blockAndTitles {
        flex-direction: column;
        gap: 5px;
    }
    .caseActive_imageAndTitle_title_item:nth-child(1) {
        flex-direction: row;
        gap: 30px;
        align-items: center;
    }
    .caseActive_imageAndTitle_title_button {
        margin-top: 20px;
    }
}