.glitch-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 150px;
}
.glitch-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: transparent;
}
.glitch {
    position: relative;
    font-size: 60px;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    letter-spacing: 5px;
    z-index: 1;
}
.glitch.light {
    color: #191840;
}
.glitch:before,
.glitch:after {
    display: block;
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
}
.glitch:before {
    animation: glitch-color 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
    color: #63b9f7;
    z-index: -1;
}
.glitch:after {
    animation: glitch-color 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
    color: #b931f9;
    z-index: -2;
}
@keyframes glitch-color {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translate(-3px, 3px);
    }
    40% {
        transform: translate(-3px, -3px);
    }
    60% {
        transform: translate(3px, 3px);
    }
    80% {
        transform: translate(3px, -3px);
    }
    to {
        transform: translate(0);
    }
}

@media ( max-width: 1100px ) {
    /* .glitch-container {
        margin-bottom: 100px;
    } */
    .glitch {
        font-size: 50px;
    }
}
@media ( max-width: 678px ) {
    .glitch-container {
        margin-bottom: 100px;
    }
    .glitch {
        font-size: 45px;
    }
}
@media ( max-width: 450px ) {
    .glitch {
        font-size: 30px;
    }
}