.contact_container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 140px 0 40px;
}
.contact_content_Form {
    display: flex;
    width: 100%;
    gap: 150px;
    max-width: 100%;
}
.contact_content {
    width: 420px;
    margin-left: 170px;
}
.contact_content_p1,
.contact_content_p2,
.contact_form_title {
    color: #4C4B80;
    font-size: 16px;
    font-weight: 400;
}
.contact_content_p1.light,
.contact_content_p2.light,
.contact_form_title.light {
    color: rgb(76, 75, 128, .5);
}
.contact_form_title {
    margin-bottom: 35px;
}

.contact_content_info {
    margin: 60px;
}
.contact_content_info_p {
    color: #4C4B80;
}
.contact_form {
    margin-right: 60px;
}
.contact_social {
    width: 100%;
    display: flex;
    justify-content: center;
}
.contact_social_content {
    display: flex;
    gap: 20px;
    position: relative;
}

@media ( max-width: 1050px ) {
    .contact_content_Form {
        gap: 100px;
    }
}
@media ( max-width: 950px ) {
    .contact_content_Form {
        gap: 50px;
    }
}
@media ( max-width: 850px ) {
    .contact_content_Form {
        gap: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .contact_content {
        width: 100%;
        margin-left: 0;
        padding: 0 40px;
    }
    .contact_form {
        margin-right: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contact_form_title {
        margin-bottom: 30px;
        width: 100%;
        max-width: 400px;
        margin: 0 80px;
    }
    .contact_form_form {
        max-width: 400px;
    }
    .contact_content_info {
        margin: 60px 40px;
    }
}
@media ( max-width: 600px ) {
    .contact_social_content {
        /* width: 100%; */
        margin: 0 40px;
        gap: 20px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}
@media ( max-width: 490px ) {
    .contact_content {
        width: 100%;
        margin-left: 0;
        padding: 0 20px;
    }
    .contact_form_form {
        padding: 0 20px;
        max-width: 100%;
    }
    .contact_form_title {
        max-width: 100%;
        padding: 0 40px 20px;
    }
    .contact_content_info {
        margin: 40px 20px;
    }
}

@media ( max-width: 300px ) {
    .contact_social_content {
        margin: 0 20px;
    }
}