@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prosto+One&display=swap');
:root {
  --backgroundDark: #191840;
  --textMainDark: #ffffff;
  --backgroundElementsDark: #2B2A61;
  --colorGreenDark: #4CE470;
  --colorYellowDark: #FFFF80;
  --colorPinkDark: #FF80BF;
}
body {
  background-color: var(--backgroundDark);
  font-family: "Poppins", sans-serif;
  user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
}
body.light {
  background-color: #fff;
}
body.hidden {
  overflow-y: hidden;
}
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background-color: var(--about_table_item);
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #B931F9 0%, #63B9F7 100%);
  border-radius: 15px;
}
.container {
  max-width: 1400px;
  margin: 0 auto;
}

/* @keyframes appear {
  from {
    opacity: 0;
    transform: translateY(500px);
    transition: transform 1s ease, scale 1s ease;
    scale: .9;
  }
  to {
    opacity: 1;
    transform: translateY(0px);
    transition: transform 1s ease, scale 1s ease;
    scale: 1;
  }
}
@keyframes appearLeft {
  from {
    opacity: 0;
    transform: translateX(-500px);
    transition: transform 1s ease, scale 1s ease;
    scale: .9;
  }
  to {
    opacity: 1;
    transform: translateX(0px);
    transition: transform 1s ease, scale 1s ease;
    scale: 1;
  }
}
@keyframes appearRight {
  from {
    opacity: 0;
    transform: translateX(500px);
    transition: transform 1s ease, scale 1s ease;
    scale: .9;
  }
  to {
    opacity: 1;
    transform: translateX(0px);
    transition: transform 1s ease, scale 1s ease;
    scale: 1;
  }
}
.section {
  animation: appear 2s linear;
  animation-timeline: view();
  animation-range: entry 0% contain 10%;
  overflow: none;
} */


/* .appearAboutCard {
  animation: appearLeft 2s linear;
  animation-timeline: view();
  animation-range: entry 0% contain 10%;

}
.appearAboutTable {
  animation: appearRight 2s linear;
  animation-timeline: view();
  animation-range: entry 0% contain 10%;
} */