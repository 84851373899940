body {
    height: 100vh;
    cursor: none;
}
.cursor-dot {
    padding-top: 20px;
    padding-left: 5px;
    z-index: 1000;
}
.cursor-effect {
    z-index: 1000;
}
.cursor-dot > img {
    width: 40px;
    height: 40px;
}
.cursor-effect > img {
    width: 50px;
    height: 50px;
    z-index: 1;
}
.cursor-pointer > img {
    width: 30px;
    height: 30px;
    z-index: 1;
}

.cursor-dot,
.cursor-effect {
    position: fixed;
    top: 0; left: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    pointer-events: none;
}
.cursor-effect {
    transition: opacity .5s;
}
.cursor-effect.show {
    opacity: 1;
}
.cursor-effect.hide {
    opacity: 0;
}



@media ( pointer: none ) {
    .cursor-dot {
        display: none;
    }
}
@media ( pointer: coarse ) {
    .cursor-dot {
        display: none;
    }
}
@media ( pointer: fine ) {
    .cursor-dot {
        display: block;
    }
}