.link_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
    margin: 70px 100px;
}
.linkOne,
.linkTwo,
.linkThree,
.linkFour,
.linkFive {
    width: 190px;
    border: 1px solid #fff3;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 50px;
    text-decoration: none;
    position: relative;
    overflow: hidden;
}
.linkSpanOne,
.linkSpanTwo,
.linkSpanThree,
.linkSpanFour,
.linkSpanFive {
    color: #fff5;
    font-size: 16px;
    font-weight: 200;
}
.linkOne.light,
.linkTwo.light,
.linkThree.light,
.linkFour.light,
.linkFive.light {
    width: 190px;
    border: 1px solid #0008;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.linkSpanOne.light,
.linkSpanTwo.light,
.linkSpanThree.light,
.linkSpanFive.light {
    color: #000;
    font-size: 16px;
    font-weight: 200;
}
.linkOne div,
.linkTwo div,
.linkThree div,
.linkFive div {
    width: 250px;
    height: 250px;
    background: linear-gradient(to right, #B931F9 0%, #63B9F7 100%);
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity .5s ease;
}

@media (hover :hover) {
    .linkOne:hover,
    .linkTwo:hover,
    .linkThree:hover,
    .linkFive:hover {
        box-shadow: 0 0 8px 7px rgba(101, 183, 248, .3),
                            inset 0 0 8px 7px rgba(101, 183, 248, .3);
        border-color: #AE43F9;
        & div {
            opacity: 1;
        }
        & .linkSpanOne,
        .linkSpanTwo,
        .linkSpanThree,
        .linkSpanFive {
            color: #fff;
            font-weight: 300;
            transition: color .5s ease, font-weight .5s ease;
        }
    }
}
@media (hover :none) {
    .linkOne:active,
    .linkTwo:active,
    .linkThree:active,
    .linkFive:active {
        box-shadow: 0 0 8px 7px rgba(101, 183, 248, .3),
                            inset 0 0 8px 7px rgba(101, 183, 248, .3);
        border-color: #AE43F9;
        & div {
            opacity: 1;
        }
        & .linkSpanOne,
        .linkSpanTwo,
        .linkSpanThree,
        .linkSpanFive {
            color: #fff;
            font-weight: 300;
            transition: color .5s ease, font-weight .5s ease;
        }
    }
}
.linkFour.disable {
    border-color: #666666;
    background-color: #666666;
    pointer-events: none;
}


@media ( max-width: 1350px ) {
    .link_container {
        gap: 30px;
        margin: 70px 40px;
    }
}
@media ( max-width: 1129px ) {
    .link_container {
        margin: 70px 30px;
    }
    .linkOne,
    .linkTwo,
    .linkThree,
    .linkFour,
    .linkFive,
    .linkOne.light,
    .linkTwo.light,
    .linkThree.light,
    .linkFour.light,
    .linkFive.light {
        width: 150px;
        padding: 10px 30px;
    }
    .linkSpanOne,
    .linkSpanTwo,
    .linkSpanThree,
    .linkSpanFour,
    .linkSpanFive,
    .linkSpanOne.light,
    .linkSpanTwo.light,
    .linkSpanThree.light,
    .linkSpanFour.light,
    .linkSpanFive.light {
        font-size: 14px;
    }
}
@media ( max-width: 900px ) {
    .link_container {
        margin: 70px 20px;
        gap: 15px;
    }
    .linkOne,
    .linkTwo,
    .linkThree,
    .linkFour,
    .linkFive,
    .linkOne.light,
    .linkTwo.light,
    .linkThree.light,
    .linkFour.light,
    .linkFive.light {
        width: 120px;
        padding: 10px 15px;
    }
}