.stagesDev-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    margin: 2rem;
    padding-bottom: 4rem;
}
.stagesDev_title {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 1030px;
    width: 100%;
    margin: 0 20px;
}
.stagesDev_title-item {
    color: #fff;
    font-size: 25px;
    text-transform: uppercase;
}
.stagesDev_title_itemOne.light,
.stagesDev_title_itemTwo.light,
.stagesDev_title_itemThree.light,
.stagesDev_title_itemOne_ru.light,
.stagesDev_title_itemTwo_ru.light,
.stagesDev_title_itemThree_ru.light {
    color: #000;
}
.stages_line {
    background-color: #fff4;
    height: 1px;
    width: 100%;
    max-width: 310px;
}
.stages_line.light,
.stages_lineTwo.light {
    background-color: #0004;
}
.stagesDev_cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1030px;
    gap: 45px;
}


@media ( max-width: 1085px ) {
    .stagesDev_title {
        max-width: 100%;
        width: 100%;
    }
    .stagesDev_cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 100%;
        width: 100%;
        gap: 20px;
    }
    .stagesDev-container {
        margin: 1rem;
    }
}
@media ( max-width: 545px ) {
    .stagesDev_cards {
        grid-template-columns: repeat(1, 1fr);
        max-width: 100%;
        width: 100%;
    }
}