.case_container {
    /* position: relative; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
}
.case_title {
    color: #fff;
    font-size: 24px;
}
.case_title.light,
.case_title_ru.light {
    color: #000;
}
.caseTitleCard_content {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: space-between;
    max-width: 740px;
    margin: 40px 20px 30px;
}
.case_text {
    width: 100%;
    color: #fff;
    font-size: 14px;
    max-width: 1000px;
    text-align: center;
    margin-bottom: 40px;
    padding: 0 20px;
}
.case_text.light,
.case_text_ru.light {
    color: #000;
}

@media ( max-width: 769px ) {
    .caseTitleCard_content {
        justify-content: center;
        max-width: 100%;
        gap: 20px;
    }
}
@media ( max-width: 540px ) {
    .caseTitleCard_content {
        gap: 10px;
        margin: 40px 10px 30px;
    }
}