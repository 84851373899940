.stack_container {
    margin: 0 2rem 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 40px;
}
.stack_content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 860px;
    row-gap: 50px;
    column-gap: 100px;
}

@media ( max-width: 990px ) {
    .stack_content {
        row-gap: 40px;
        column-gap: 80px;
    }
}
@media ( max-width: 768px ) {
    .stack_content {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media ( max-width: 540px ) {
    .stack_container {
        margin: 0 1rem 100px;
    }
    .stack_content {
        row-gap: 30px;
        column-gap: 30px;
    }
}
@media ( max-width: 280px ) {
    .stack_content {
        grid-template-columns: repeat(1, 1fr);
    }
}