.techno-card {
    display: flex;
    flex-direction: column;
    padding: 5px;
    background-color: #2B2A61;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 85px;
    gap: 5px;
}
.techno-card-img {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.techno-card span {
    font-size: 12px;
    color: #fff;
}

@media ( hover :hover ) {
    .techno-card:hover {
        border: 1px solid #65B7F8;
        box-shadow: inset 0 0 12px 4px rgba(101, 183, 248, .5);
    }
}
@media ( hover :none ) {
    .techno-card:active {
        border: 1px solid #65B7F8;
        box-shadow: inset 0 0 12px 4px rgba(101, 183, 248, .5);
    }
}



@media ( max-width: 700px ) {
    .techno-card {
        width: 100%;
    }
}