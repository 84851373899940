.codeScreen_container {
    margin: 70px 60px;
    position: relative;
}
.codeScreen_image {
    width: 100%;
    border-radius: 20px;
    position: relative;
    pointer-events: none;
}
.codeScreen_container::before {
    content: '';
    background-color: #2B2A61;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: -10px; right: -15px;
    border-radius: 20px;
}
.codeScreen_container.light::before {
    background-color: rgba(43, 42, 97, .7);
}

@media ( max-width: 900px ) {
    .codeScreen_container {
        margin: 70px 20px;
    }
    .codeScreen_image {
        border-radius: 15px;
    }
    .codeScreen_container::before {
        bottom: -5px; right: -10px;
        border-radius: 15px;
    }
}
@media ( max-width: 450px ) {
    .codeScreen_image {
        border-radius: 10px;
    }
    .codeScreen_container::before {
        bottom: -2px; right: -7px;
        border-radius: 10px;
    }
}


@keyframes appearCodeImageDesktop {
    from {
        opacity: 0;
        height: 20px;
        transition: transform 1s ease, scale 1s ease;
        scale: .9;
    }
    to {
        opacity: 1;
        height: 150px;
        transition: transform 1s ease, scale 1s ease;
        scale: 1;
    }
}
@keyframes appearCodeImageMobile549 {
    from {
        opacity: 0;
        height: 20px;
        transition: transform 1s ease, scale 1s ease;
        scale: .9;
    }
    to {
        opacity: 1;
        height: 120px;
        transition: transform 1s ease, scale 1s ease;
        scale: 1;
    }
}
@keyframes appearCodeImageMobile430 {
    from {
        opacity: 0;
        height: 20px;
        transition: transform 1s ease, scale 1s ease;
        scale: .9;
    }
    to {
        opacity: 1;
        height: 100px;
        transition: transform 1s ease, scale 1s ease;
        scale: 1;
    }
}
@media ( min-width: 550px ) {
    .codeScreen_image {
        animation: appearCodeImageDesktop 2s 1;
        animation-timeline: view();
    }
}
@media ( max-width: 549px ) {
    .codeScreen_image {
        animation: appearCodeImageMobile549 2s 1;
        animation-timeline: view();
    }
}
@media ( max-width: 430px ) {
    .codeScreen_image {
        animation: appearCodeImageMobile430 2s 1;
        animation-timeline: view();
    }
}