.contact_social_content_item {
    width: 40px;
    height: 40px;
    position: relative;
}
.contact_social_content_item_link {
    width: 100%;
    height: 100%;
    text-decoration: none;
    position: relative;
}
.socialOriginal,
.socialLight,
.socialDark {
    width: 40px;
    height: 40px;
    object-fit: cover;
}
.socialOriginal {
    position: relative;
    opacity: 0;
    transition: opacity .4s ease;
    z-index: 2;
}
.socialDark,
.socialLight {
    position: absolute;
    opacity: .4;
    top: -23px; left: 0; right: 0; bottom: 0;
    z-index: 1;
    transition: opacity .4s ease;
}
.socialDark {
    display: none;
}

.contact_social_content_item1.light .contact_social_content_item_link .socialLight,
.contact_social_content_item2.light .contact_social_content_item_link .socialLight,
.contact_social_content_item3.light .contact_social_content_item_link .socialLight,
.contact_social_content_item4.light .contact_social_content_item_link .socialLight,
.contact_social_content_item5.light .contact_social_content_item_link .socialLight,
.contact_social_content_item6.light .contact_social_content_item_link .socialLight,
.contact_social_content_item7.light .contact_social_content_item_link .socialLight,
.contact_social_content_item8.light .contact_social_content_item_link .socialLight {
    display: none;
}
.contact_social_content_item1.light .contact_social_content_item_link .socialDark,
.contact_social_content_item2.light .contact_social_content_item_link .socialDark,
.contact_social_content_item3.light .contact_social_content_item_link .socialDark,
.contact_social_content_item4.light .contact_social_content_item_link .socialDark,
.contact_social_content_item5.light .contact_social_content_item_link .socialDark,
.contact_social_content_item6.light .contact_social_content_item_link .socialDark,
.contact_social_content_item7.light .contact_social_content_item_link .socialDark,
.contact_social_content_item8.light .contact_social_content_item_link .socialDark {
    display: block;
}


@media (hover :hover) {
    .contact_social_content_item_link:hover .socialOriginal {
        opacity: 1;
    }
    .contact_social_content_item_link:hover .socialDark, 
    .contact_social_content_item_link:hover .socialLight {
        opacity: 0;
    }
}
@media (hover :none) {
    .contact_social_content_item_link:active .socialOriginal {
        opacity: 1;
    }
    .contact_social_content_item_link:active .socialDark, 
    .contact_social_content_item_link:active .socialLight {
        opacity: 0;
    }
}

@media ( max-width: 300px ) {
    .contact_social_content_item {
        width: 30px;
        height: 30px;
        position: relative;
    }
    .socialOriginal,
    .socialLight,
    .socialDark {
        width: 30px;
        height: 30px;
        object-fit: cover;
    }
}