.buttonLang_container {
    /* width: 51px; */
    width: 25px;
    height: 25px;
    /* border: 1px solid #fff8; */
    /* border-radius: 5px; */
    border-radius: 50%;
    background-color: transparent;
    /* padding: 1px; */
    position: relative;
}
@media ( max-width: 490px ) {
    .buttonLang_container.light {
        border: 1px solid #4D4F5D;
    }
}
.langs {
    /* width: 21px;
    height: 21px; */
    width: 100%;
    height: 100%;
    /* background-color: #B931F9; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border-radius: 50%;
    /* transform: translateX(0); */
    /* transform: translateX(0); */
    /* transition: transform .5s ease, background-color  .5s ease, background .5s ease; */
    transition: background-color 0.2s ease;
    position: relative;
}
.langs::before {
    content: '';
    background: linear-gradient(to right, #B931F9 0%, #63B9F7 100%);
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 50%;
    transform: rotate(0deg);
    transition: transform .4s linear;
}
.buttonLang_container:hover .langs::before {
    transform: rotate(180deg);
}
.lang {
    font-size: 12px;
    font-weight: 100;
    color: #fff;
    text-transform: uppercase;
}
.langs.ru .langs::before {
    transform: rotate(180deg);
    /* transform: translateX(26px); */
    /* background: linear-gradient(to left, #B931F9 0%, #63B9F7 100%); */
    /* background-color: #63B9F7; */
}


.ul_en,
.ul_ru,
.mobile_ul_en,
.mobile_ul_ru,
.menu_itemOne_en,
.menu_itemOne_ru,
.menu_itemTwo_en,
.menu_itemTwo_ru,
.menu_itemThree_en,
.menu_itemThree_ru,
.menu_itemFour_en,
.menu_itemFour_ru,
.menu_itemFive_en,
.menu_itemFive_ru,
.aboutTitle_en,
.aboutTitle_ru,
.aboutSubtitle_en,
.aboutSubtitle_ru,
.hello_en,
.hello_ru,
.my_name_en,
.my_name_ru,
.about_table_title1_en,
.about_table_title1_ru,
.about_table_text1_en,
.about_table_text1_ru,
.about_table_title2_en,
.about_table_title2_ru,
.about_table_text2_en,
.about_table_text2_ru,
.about_table_title3_en,
.about_table_title3_ru,
.about_table_text3_en,
.about_table_text3_ru,
.about_table_title4_en,
.about_table_title4_ru,
.about_table_text4_en,
.about_table_text4_ru,
.technologies_text_en,
.technologies_text_ru,
.resultExperience_title_en,
.resultExperience_title_ru,
.resultExperience_number_item_text1_en,
.resultExperience_number_item_text1_ru,
.resultExperience_number_item_text2_en,
.resultExperience_number_item_text2_ru,
.resultExperience_number_item_text3_en,
.resultExperience_number_item_text3_ru,
.stackActive_titleBlock_en,
.stackActive_titleBlock_ru,
.stagesDev_title_itemOne_en,
.stagesDev_title_itemOne_ru,
.stagesDev_title_itemTwo_en,
.stagesDev_title_itemTwo_ru,
.stagesDev_title_itemThree_en,
.stagesDev_title_itemThree_ru,
.stagesDevCard_front_content_title1_en,
.stagesDevCard_front_content_title1_ru,
.stagesDevCard_front_content_title2_en,
.stagesDevCard_front_content_title2_ru,
.stagesDevCard_front_content_title3_en,
.stagesDevCard_front_content_title3_ru,
.stagesDevCard_front_content_title4_en,
.stagesDevCard_front_content_title4_ru,
.stagesDevCard_backH3_1_en,
.stagesDevCard_backH3_1_ru,
.stagesDevCard_backH3_2_en,
.stagesDevCard_backH3_2_ru,
.stagesDevCard_backH3_3_en,
.stagesDevCard_backH3_3_ru,
.stagesDevCard_backH3_4_en,
.stagesDevCard_backH3_4_ru,
.stagesDevCard_backP_1_en,
.stagesDevCard_backP_1_ru,
.stagesDevCard_backP_2_en,
.stagesDevCard_backP_2_ru,
.stagesDevCard_backP_3_en,
.stagesDevCard_backP_3_ru,
.stagesDevCard_backP_4_en,
.stagesDevCard_backP_4_ru,
.case_title_en,
.case_title_ru,
.case_text_en,
.case_text_ru,
.stagesOfImplementation_title_en,
.stagesOfImplementation_title_ru,

.stagesOfImplementation_item_info_content_title1_en,
.stagesOfImplementation_item_info_content_title1_ru,
.stagesOfImplementation_item_info_content_text1_en,
.stagesOfImplementation_item_info_content_text1_ru,

.stagesOfImplementation_item_info_content_title2_en,
.stagesOfImplementation_item_info_content_title2_ru,
.stagesOfImplementation_item_info_content_text2_en,
.stagesOfImplementation_item_info_content_text2_ru,

.stagesOfImplementation_item_info_content_title3_en,
.stagesOfImplementation_item_info_content_title3_ru,
.stagesOfImplementation_item_info_content_text3_en,
.stagesOfImplementation_item_info_content_text3_ru,

.stagesOfImplementation_item_info_content_title4_en,
.stagesOfImplementation_item_info_content_title4_ru,
.stagesOfImplementation_item_info_content_text4_en,
.stagesOfImplementation_item_info_content_text4_ru,

.attention_text_en,
.attention_text_ru,

.reviews_content,
.reviews_contentRu,
.reviews_title_en,
.reviews_title_ru,
.actReviewEn,
.actReviewRu,

.contact_content_p1_en,
.contact_content_p1_ru,
.contact_content_p2_en,
.contact_content_p2_ru,
.contact_content_info_p_en,
.contact_content_info_p_ru,
.contact_form_title_en,
.contact_form_title_ru,

.form_item1_en,
.form_item1_ru,
.form_item2_en,
.form_item2_ru,
.form_item3_en,
.form_item3_ru,
.form_item4_en,
.form_item4_ru,

.policy_en,
.policy_ru,
.up_en,
.up_ru,
.mobile_up_en,
.mobile_up_ru,
.mobile_policy_en,
.mobile_policy_ru

{
    &.displayNone  {

        display: none;
    }
}