.caseTitleCard_container {
    padding: 10px 30px;
    background-color: #2B2A61;
    border-radius: 20px;
    border: 1px solid #2B2A61;
}
.caseTitleCard_container span {
    color: #fff;
    display: flex;
    flex-wrap: nowrap;
    font-size: 14px;
}
.caseTitleCard_container.active {
    border: 1px solid #65B7F8;
    box-shadow: inset 0 0 12px 4px rgba(101, 183, 248, .5);
}
@media ( hover :hover ) {
    .caseTitleCard_container:hover {
        border: 1px solid rgb(101, 183, 248, .7);
        box-shadow: inset 0 0 12px 4px rgba(101, 183, 248, .3);
    }
}
@media ( hover :none ) {
    .caseTitleCard_container:active {
        border: 1px solid rgb(101, 183, 248, .7);
        box-shadow: inset 0 0 12px 4px rgba(101, 183, 248, .3);
    }
}