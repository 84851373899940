.mobile-menu {
    z-index: 100;
    position: fixed;
    top: 50px; right: 0;
    width: 0;
    background: rgba(0, 0, 0, .1);
    backdrop-filter: blur(50px);
    border-radius: 10px 0 0 10px;
    padding: 20px;
    transition: all .3s ease;
    transform: translateX(100vw);
    opacity: 0;
}
.mobile-menu.active {
    opacity: 1;
    transform: translateX(0);
    width: 200px;
}
.mobile-menu-container > ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.menu_itemOne.light,
.menu_itemTwo.light,
.menu_itemThree.light,
.menu_itemFour.light,
.menu_itemFive.light,
.mobile_menu_itemOne_en.light,
.mobile_menu_itemTwo_en.light,
.mobile_menu_itemThree_en.light,
.mobile_menu_itemFour_en.light,
.mobile_menu_itemFive_en.light,
.mobile_menu_itemOne_ru.light,
.mobile_menu_itemTwo_ru.light,
.mobile_menu_itemThree_ru.light,
.mobile_menu_itemFour_ru.light,
.mobile_menu_itemFive_ru.light {
    color: #000;
}

@media ( min-width: 770px ) {
    .theme.mobile {
        display: none; 
    }
}
@media(max-width: 769px) {
    .mobile-menu {
        display: block;
    }
    .mobile-menu ul li {
        width: 100%;
    }
    .mobile-menu ul li a {
        width: 100%;
        font-size: 1.1rem;
    }
    .theme.mobile {
        display: block; 
    }
}
@media(max-width: 540px) {
    .mobile-menu ul li a {
        font-size: 1rem;
    }
    .mobile-menu.active {
        width: 40%;
    }
    .mobile-menu-container {
        width: 100%;
    }
}
@media(max-width: 375px) {
    .mobile-menu.active {
        width: 70%;
    }
}